import React from 'react';
import ReactDOM from 'react-dom';
import Auth from '@aws-amplify/auth';
import { AwsUi } from '@amzn/awsui-components-react/polaris/runtime';
import './bootstrap';
import configService from './services/config';
import metricsService from './services/metrics';
import { tap } from './utils/promise';
import logger from './utils/logger';
import { captureTimingMetrics } from './utils/performance';
import providersService from './services/providers';
import ErrorBoundary from './components/ErrorBoundary';
import AppInitFail from './pages/AppInitFail';
import App from './App';

import LocaleContextProvider from './contexts/LocaleContextProvider';
import { MemoryStorage } from '@aws-amplify/core';

AwsUi.config({
  production: process.env.NODE_ENV !== 'development',
  logLevel: 3,
});

Promise.resolve()
  .then(configService.init)
  .then(tap(metricsService.init))
  .then(tap(captureTimingMetrics))
  .then((config) => {
    const publisher = metricsService.getPublisher('Index');
    publisher.publishCounterMonitor('Impressions', 1);

    if (window.location.pathname === '/login') {
      // Check if the customer has provided a preferred IDP through the URL.
      // In that case we'll redirect immediately instead of showing the UI,
      // which is also the expected behavior in Cognito Hosted UI.
      const preferredIdp = providersService.getPreferredIdp(config);
      if (preferredIdp) {
        window.location.href = providersService.getIdpUrl(
          preferredIdp,
          config.cognitoDomain
        );
        publisher.publishCounterMonitor('PreferredIdpImpression', 1);
        publisher.publishStringTruncate('PreferredIdpName', preferredIdp);
        return; // Early return before rendering the app.
      }
    }

    if (config.enableOtp === 'true') {
      Auth.configure({
        Auth: {
          storage: MemoryStorage,
          region: config.region,
          userPoolId: config.otpUserPoolId,
          userPoolWebClientId: config.otpUserPoolClientId,
        },
      });
    }

    ReactDOM.render(
      <React.StrictMode>
        <LocaleContextProvider>
          <ErrorBoundary>
            <App config={config} />
          </ErrorBoundary>
        </LocaleContextProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((err) => {
    logger.error(err);
    try {
      const publisher = metricsService.getPublisher('InitializeError');
      publisher.publishCounterMonitor('Impressions', 1);
      publisher.publishStringTruncate('Message', err.toString() || 'NA');
      publisher.publishStringTruncate('UserAgent', navigator.userAgent);
      const urlParams = new URLSearchParams(window.location.search);
      publisher.publishStringTruncate(
        `ClientId`,
        urlParams.get('client_id') || 'NA'
      );
    } catch (_) {
      // We tried
    }

    ReactDOM.render(
      <LocaleContextProvider>
        <AppInitFail />
      </LocaleContextProvider>,
      document.getElementById('root')
    );
  });
