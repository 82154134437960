import React, { useEffect } from 'react';
import { FormSection, Alert } from '@amzn/awsui-components-react/polaris';
import { useIntl } from 'react-intl';

import Container from '../components/Container';
import metricsService from '../services/metrics';
import HelpLink from '../components/HelpLink';
import errorMessages from '../i18n/error.messages';

function Error({ details }: { details?: string }) {
  const { formatMessage } = useIntl();
  // Need to refactor this to explain what the required params are etc
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id') || 'NA';
    const metricsPublisher = metricsService.getPublisher('ErrorPage');
    metricsPublisher.publishCounterMonitor('Impressions', 1);
    metricsPublisher.publishStringTruncate(`ClientId`, clientId);
    if (details) metricsPublisher.publishStringTruncate('Details', details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <FormSection
        header={
          <h1 className="form-section-h1">
            {formatMessage(errorMessages.errorHeader)}
          </h1>
        }
        description={formatMessage(errorMessages.errorSubHeaderDescription)}
      >
        <p data-testid="error-message">
          {formatMessage(errorMessages.generalErrorMessage)}
        </p>
        {details && (
          <Alert type="warning" data-testid="error-details">
            {details}
          </Alert>
        )}
        <HelpLink />
      </FormSection>
    </Container>
  );
}

export default Error;
