import React, { Component, ErrorInfo } from 'react';
import logger from '../utils/logger';

class ErrorBoundary extends Component<{ FallbackComponent?: React.ReactType }> {
  public state = {
    hasError: false,
    error: null,
  };

  componentDidCatch(error: Error | null, errorInfo: ErrorInfo) {
    logger.error('ErrorBoundary', error, errorInfo);
    this.setState({
      hasError: true,
      error: error,
    });
  }

  render() {
    if (this.state.hasError) {
      const FallbackComponent = this.props.FallbackComponent;
      return FallbackComponent ? (
        <FallbackComponent
          details={this.state.error && (this.state.error as any).toString()}
        />
      ) : (
        <p>Something went wrong, please reload the page and try again.</p>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
