import React from 'react';
import { useIntl } from 'react-intl';

import {
  faqMessages,
  troubleshootingMessages,
  privacyMessages,
  tandCMessages,
} from '../i18n/helpContent.messages';
import helpMessages from '../i18n/help.messages';

export type HelpTopic = {
  heading: string;
  content: React.ReactNode;
};

export type HelpSection = {
  heading: string;
  topics: HelpTopic[];
};

export type HelpContent = {
  sections: HelpSection[];
};

// TODO: In future iterations we will refactor so that the content is either
// fetched from an API or hosted as a separate page or app.
// The content supports JSX to ensure we enable links and other elements.
export function Content(): HelpContent {
  const { formatMessage } = useIntl();
  return {
    sections: [
      {
        heading: formatMessage(helpMessages.sectionTroubleshooting),
        topics: [
          {
            heading: formatMessage(
              troubleshootingMessages['signinMethodtoUse:question']
            ),
            content: formatMessage(
              troubleshootingMessages['signinMethodtoUse:answer']
            ),
          },
          {
            heading: formatMessage(
              troubleshootingMessages['pageSaysIdontHaveAccess:question']
            ),
            content: formatMessage(
              troubleshootingMessages['pageSaysIdontHaveAccess:answer']
            ),
          },
          {
            heading: formatMessage(
              troubleshootingMessages['cantSignPreferredMethod:question']
            ),
            content: formatMessage(
              troubleshootingMessages['cantSignPreferredMethod:answer'],
              {
                // These makes sure our help content can have some formatted content.
                ol: (str) => <ol>{str}</ol>,
                ul: (str) => <ul>{str}</ul>,
                li: (str) => <li>{str}</li>,
                i: (str) => <i>{str}</i>,
                supportLink: (str) => (
                  <a
                    href="https://www.amazon.com/gp/help/customer/display.html?nodeId=G4CY3HAXTSFLGC22"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {str}
                  </a>
                ),
              }
            ),
          },
          {
            heading: formatMessage(
              troubleshootingMessages['dontHaveASignInMethod:question']
            ),
            content: formatMessage(
              troubleshootingMessages['dontHaveASignInMethod:answer']
            ),
          },
          {
            heading: formatMessage(
              troubleshootingMessages['didntReceiveOTPEmail:question']
            ),
            content: formatMessage(
              troubleshootingMessages['didntReceiveOTPEmail:answer'],
              {
                // These makes sure our help content can have some formatted content.
                ol: (str) => <ol>{str}</ol>,
                ul: (str) => <ul>{str}</ul>,
                li: (str) => <li>{str}</li>,
                i: (str) => <i>{str}</i>,
              }
            ),
          },
          {
            heading: formatMessage(
              troubleshootingMessages['cantFindHelpSignInIssue:question']
            ),
            content: formatMessage(
              troubleshootingMessages['cantFindHelpSignInIssue:answer'],
              {
                // These makes sure our help content can have some formatted content.
                supportLink: (str) => (
                  <a
                    href="https://support.aws.amazon.com/#/contacts/aws-training"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {str}
                  </a>
                ),
              }
            ),
          },
        ],
      },
      {
        heading: formatMessage(helpMessages.sectionFAQ),
        topics: [
          {
            heading: formatMessage(
              faqMessages['doesMatterWhichSignInMethod:question']
            ),
            content: formatMessage(
              faqMessages['doesMatterWhichSignInMethod:answer']
            ),
          },
          {
            heading: formatMessage(
              faqMessages['canISignWithPreviousIdentity:question']
            ),
            content: formatMessage(
              faqMessages['canISignWithPreviousIdentity:answer']
            ),
          },
          {
            heading: formatMessage(faqMessages['whatIsLWA:question']),
            content: formatMessage(faqMessages['whatIsLWA:answer']),
          },
          {
            heading: formatMessage(faqMessages['whatIsOTP:question']),
            content: formatMessage(faqMessages['whatIsOTP:answer']),
          },
          {
            heading: formatMessage(
              faqMessages['whatBrowserAreSupported:question']
            ),
            content: formatMessage(
              faqMessages['whatBrowserAreSupported:answer']
            ),
          },
          {
            heading: formatMessage(faqMessages['signWithAWSLogin:question']),
            content: formatMessage(faqMessages['signWithAWSLogin:answer']),
          },
          {
            heading: formatMessage(faqMessages['storeSignInPassword:question']),
            content: formatMessage(faqMessages['storeSignInPassword:answer']),
          },
        ],
      },
      {
        heading: formatMessage(helpMessages.sectionPrivacy),
        topics: [
          {
            heading: formatMessage(
              privacyMessages['complyingWithGPDR:question']
            ),
            content: formatMessage(privacyMessages['complyingWithGPDR:answer']),
          },
          {
            heading: formatMessage(privacyMessages['whatIsGPDR:question']),
            content: formatMessage(privacyMessages['whatIsGPDR:answer']),
          },
          {
            heading: formatMessage(
              privacyMessages['personalInfoDeletedRequest:question']
            ),
            content: formatMessage(
              privacyMessages['personalInfoDeletedRequest:answer'],
              {
                // These makes sure our help content can have some formatted content.
                supportLink: (str) => (
                  <a
                    href="https://support.aws.amazon.com/#/contacts/aws-training"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {str}
                  </a>
                ),
              }
            ),
          },
          {
            heading: formatMessage(
              privacyMessages['personalInfoDeletedDownload:question']
            ),
            content: formatMessage(
              privacyMessages['personalInfoDeletedDownload:answer'],
              {
                // These makes sure our help content can have some formatted content.
                supportLink: (str) => (
                  <a
                    href="https://support.aws.amazon.com/#/contacts/aws-training"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {str}
                  </a>
                ),
              }
            ),
          },
        ],
      },
      {
        heading: formatMessage(helpMessages.sectionTermAndConditions),
        topics: [
          {
            heading: formatMessage(
              tandCMessages['whatAreTermAndConditions:question']
            ),
            content: formatMessage(
              tandCMessages['whatAreTermAndConditions:answer'],
              {
                // These makes sure our help content can have some formatted content.
                supportLink: (str) => (
                  <a
                    href="https://aws.amazon.com/terms/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {str}
                  </a>
                ),
              }
            ),
          },
        ],
      },
    ],
  };
}
