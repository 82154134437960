import React, { SyntheticEvent, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useHistory } from 'react-router-dom';
import {
  FormSection,
  FormField,
  Input,
  Button,
  ColumnLayout,
  Alert,
  Spinner,
} from '@amzn/awsui-components-react/polaris';
import { AppConfig } from '../services/config';
import {
  createCognitoUser,
  answerCustomChallenge,
  keepSessionOpen,
  startSession,
  getResponseUrl,
  globalSignOut,
} from '../services/emailOTPAuth';
import logger from '../utils/logger';
import Container from '../components/Container';
import { UserAndAuthStatus } from '../shared/userAndAuthStatus.interface';
import HelpLink from '../components/HelpLink';
import usePageTitle from '../utils/usePageTitle';
import challengeMessages from '../i18n/answerChallenge.messages';

import styles from './input.module.css';

function AnswerChallenge({ config }: { config: AppConfig }) {
  usePageTitle('Verify one-time email passcode');
  const { formatMessage } = useIntl();

  const history = useHistory();
  const hState = history.location.state;
  let errorMessage = formatMessage(challengeMessages.errorMessage);

  let cognitoUserName = '';
  let cognitoSession = '';
  let userEmail = '';
  try {
    cognitoUserName = (hState as any).cognitoUserName;
    cognitoSession = (hState as any).cognitoSession;
    userEmail = (hState as any).userEmail;
  } catch (err) {
    throw new Error('No valid session.');
  }

  const keepAliveIntervalMs = 150000; // 2.5 minutes
  const keepAliveMaxAttempts = 6; // keep Cognito session alive for at least 15 minutes
  const [keepAliveCount, setKeepAliveCount] = useState(0);
  const [keepAliveTimer, setKeepAliveTimer] = useState(0);

  const refCognitoUser = useRef(undefined);
  const [otpInput, setOtpInput] = useState<Input | null>(null);
  const [otpValue, setOtpValue] = useState('');
  const [otpEntered, setOtpEntered] = useState(false);
  const [successMessages, setSuccessMessages] = useState(['']);
  const [passCodeError, setPassCodeError] = useState('');
  const [loading, setLoading] = useState(false);

  let successAlertBar = successMessages
    .filter((msg) => msg)
    .map((msg) => (
      <Alert key={msg} type="success" dismissible={true}>
        {msg}
      </Alert>
    ));

  useEffect(() => {
    // when component mounts, we must get the current Cognito user
    if (!refCognitoUser.current) {
      getUser();
    }

    if (keepAliveTimer === 0) {
      startKeepAlive();
    }

    if (keepAliveCount >= keepAliveMaxAttempts) {
      stopKeepAlive();
    }
  });

  useEffect(() => {
    otpInput?.focus();
  }, [otpInput, passCodeError]);

  const getUser = async () => {
    refCognitoUser.current = (await getCognitoUser()) as any;
  };

  const startKeepAlive = (reset = false) => {
    if (reset) {
      setKeepAliveCount(0);
    } else if (keepAliveCount >= keepAliveMaxAttempts) {
      return;
    }

    const timer = window.setInterval(async () => {
      try {
        refCognitoUser.current = (await keepSessionOpen(
          refCognitoUser.current
        )) as any;
      } catch (e) {
        // encountered an error; assume session has timed out
        stopKeepAlive();
      }

      setKeepAliveCount((prevCount) => prevCount + 1);
    }, keepAliveIntervalMs);
    setKeepAliveTimer(timer);
  };

  const stopKeepAlive = () => {
    window.clearInterval(keepAliveTimer);
  };

  const handleCodeChange = (e: CustomEvent) => {
    if (isNineDigitCode(e.detail.value)) {
      setOtpEntered(true);
      setPassCodeError('');
    } else {
      setOtpEntered(false);
    }
    setOtpValue(e.detail.value);
  };

  const isNineDigitCode = (input: string) => {
    const nineDigitRegExp = new RegExp('^[0-9]{9}$');
    return nineDigitRegExp.test(input);
  };

  const getCognitoUser = async () => {
    if (cognitoUserName && cognitoSession) {
      const cognitoUser = await createCognitoUser(
        cognitoUserName,
        cognitoSession
      );
      return cognitoUser;
    } else {
      //TODO: User should not be clicking on button without a session
    }
  };

  const handleSendSecretCode = async (e?: CustomEvent) => {
    e?.preventDefault();

    if (!isNineDigitCode(otpValue)) {
      setPassCodeError(errorMessage);
      return;
    }
    try {
      setLoading(true);
      stopKeepAlive();
      const resultOfCustomChallenge: UserAndAuthStatus = await answerCustomChallenge(
        otpValue,
        refCognitoUser.current
      );
      refCognitoUser.current = resultOfCustomChallenge.cognitoUser as any;
      setLoading(false);
      if (resultOfCustomChallenge.isAuthenticated) {
        setLoading(true);
        setSuccessMessages(
          successMessages.concat(
            formatMessage(challengeMessages.successMessage)
          )
        );

        const urlParams = new URLSearchParams(history.location.search);
        const redirectUriParam = urlParams.get('redirect_uri');
        const stateParam = urlParams.get('state');
        const idToken = resultOfCustomChallenge.cognitoUser
          .getSignInUserSession()!
          .getIdToken()
          .getJwtToken();
        const accessToken = resultOfCustomChallenge.cognitoUser
          .getSignInUserSession()!
          .getAccessToken()
          .getJwtToken();

        const responseUrl = await getResponseUrl(
          config,
          idToken,
          accessToken,
          redirectUriParam!,
          stateParam!
        );
        await globalSignOut();
        window.location.assign(responseUrl);
      } else {
        setPassCodeError(errorMessage);
        startKeepAlive();
      }
    } catch (err) {
      logger.error(err);
      // Number of allowed wrong attempts exceeded or session expired, so send user back
      history.push({
        pathname: '/otp/input',
        search: history.location.search,
        state: {
          sessionExpired: true,
          userEmail: userEmail,
        },
      });
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    await handleSendSecretCode();
  };

  const handleResendPasscode = async (e: SyntheticEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      stopKeepAlive();
      refCognitoUser.current = (await startSession(userEmail)) as any;
      startKeepAlive(true);
      setLoading(false);
      setSuccessMessages(
        successMessages.concat(
          formatMessage(challengeMessages.resendMessage, { email: userEmail })
        )
      );
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <Container>
      <div className={loading ? 'disabled-div' : undefined}>
        <form onSubmit={handleSubmit}>
          <FormSection
            header={
              <h1 className="form-section-h1">
                {formatMessage(challengeMessages.header)}
              </h1>
            }
          >
            {loading ? (
              <div className={'center-spinner'}>
                <Spinner size="large" />
              </div>
            ) : null}
            <ColumnLayout>
              <div data-awsui-column-layout-root="true">
                <div>
                  {formatMessage(challengeMessages.description, {
                    userEmail: userEmail,
                  })}
                </div>
                <FormField
                  label={
                    <div>
                      {formatMessage(challengeMessages.passcodeInputName)}
                      {'  '}
                      <a href="#/" onClick={handleResendPasscode}>
                        {formatMessage(challengeMessages.resendPasscodeLabel)}
                      </a>
                    </div>
                  }
                  errorText={passCodeError}
                  stretch={true}
                >
                  <Input
                    name="secretCode"
                    type="text"
                    data-testid="text-input"
                    onInput={handleCodeChange}
                    disabled={loading}
                    autocomplete={false}
                    ariaRequired={true}
                    className={styles.mobileInput}
                    ref={setOtpInput}
                  />
                </FormField>
                <div>
                  <span className="awsui-util-f-r">
                    <Button
                      data-testid="back-button"
                      onClick={() => {
                        stopKeepAlive();
                        history.goBack();
                      }}
                      formAction="none"
                    >
                      {formatMessage(challengeMessages.previousButtonLabel)}
                    </Button>
                    <Button
                      data-testid="submit-button"
                      variant="primary"
                      formAction="submit"
                      onClick={handleSendSecretCode}
                      disabled={!otpEntered || loading}
                    >
                      {formatMessage(challengeMessages.buttonLabel)}
                    </Button>
                  </span>
                </div>
              </div>
            </ColumnLayout>
            <HelpLink />
          </FormSection>
        </form>
        {successAlertBar}
      </div>
    </Container>
  );
}

export default AnswerChallenge;
