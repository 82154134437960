import { LocaleCode } from '../../constants/locales';
import ja_JP from './ja-JP.json';
import ko_KR from './ko-KR.json';
import id_ID from './id-ID.json';
import es_ES from './es-ES.json';
import pt_BR from './pt-BR.json';
import de_DE from './de-DE.json';
import fr_FR from './fr-FR.json';
import zh_CN from './zh-CN.json';
import zh_TW from './zh-TW.json';
import it_IT from './it-IT.json';
import es_419 from './es-419.json';
import he_IL from './he-IL.json';
import vi_VN from './vi-VN.json';
import th_TH from './th-TH.json';
import el_GR from './el-GR.json';
import ar_SA from './ar-SA.json';
import ru_RU from './ru-RU.json';
import tr_TR from './tr-TR.json';
import pl_PL from './pl-PL.json';

const translations: Record<string, Record<string, string>> = {
  [LocaleCode.ja_JP]: ja_JP,
  [LocaleCode.ko_KR]: ko_KR,
  [LocaleCode.id_ID]: id_ID,
  [LocaleCode.es_ES]: es_ES,
  [LocaleCode.pt_BR]: pt_BR,
  [LocaleCode.de_DE]: de_DE,
  [LocaleCode.fr_FR]: fr_FR,
  [LocaleCode.zh_CN]: zh_CN,
  [LocaleCode.zh_TW]: zh_TW,
  [LocaleCode.it_IT]: it_IT,
  [LocaleCode.es_419]: es_419,
  [LocaleCode.he_IL]: he_IL,
  [LocaleCode.vi_VN]: vi_VN,
  [LocaleCode.th_TH]: th_TH,
  [LocaleCode.el_GR]: el_GR,
  [LocaleCode.ar_SA]: ar_SA,
  [LocaleCode.ru_RU]: ru_RU,
  [LocaleCode.tr_TR]: tr_TR,
  [LocaleCode.pl_PL]: pl_PL,
};

export default translations;
