import * as KatalMetrics from '@katal/metrics';
import KatalMetricsDriverSushi from '@katal/metricsDriverSushi';
import KatalMetricsDriverArrayCollector from '@katal/metrics/lib/driver/KatalMetricsDriverArrayCollector';
import KatalMetricsDriverConsoleLogJson from '@katal/metrics/lib/driver/KatalMetricsDriverConsoleLogJson';

import { AppConfig } from './config';
import logger from '../utils/logger';
import { isDevelopment } from '../utils/env';

const SITE_NAME = 'GandalfSignIn';
const SERVICE_NAME = 'WebApp';

let cachedConfig: AppConfig;
let cachedPublisher: KatalMetrics.Publisher;

const createMetricsDriver = (): KatalMetrics.MetricsDriver => {
  // If config is not initialized use a no-op driver
  // Check this first to make it obvious during development if you forget to call initMetrics()
  if (!cachedConfig?.metricsDomain || !cachedConfig?.metricsRealm) {
    logger.debug('Use KatalMetricsDriverArrayCollector');
    const metricsDriver = new KatalMetricsDriverArrayCollector();
    //  Attach to global window object so tests can see it
    (window as any).metricsDriver = metricsDriver;
    return metricsDriver;
  }

  // Publish metrics to logger for development
  if (isDevelopment) {
    logger.debug('Use KatalMetricsDriverConsoleLogJson');
    return new KatalMetricsDriverConsoleLogJson(logger);
  }

  // For non-development when config has been initialized publish metrics to sushi
  logger.debug('Use KatalMetricsDriverSushi');
  return new KatalMetricsDriverSushi.Builder()
    .withDomainRealm(cachedConfig.metricsDomain, cachedConfig.metricsRealm)
    .withErrorHandler(metricsErrorHandler)
    .build();
};

const metricsErrorHandler = isDevelopment ? logger.error : () => {};

const createInitialMetricsContext = (): KatalMetrics.Context => {
  return new KatalMetrics.Context.Builder()
    .withSite(SITE_NAME)
    .withServiceName(SERVICE_NAME)
    .build();
};

const createPublisher = (): KatalMetrics.Publisher => {
  return new KatalMetrics.Publisher(
    createMetricsDriver(),
    metricsErrorHandler,
    createInitialMetricsContext()
  );
};

export default {
  init(config: AppConfig): void {
    cachedConfig = config;
  },

  // Note: Some adblockers may block metrics being posted from localhost. See: https://sage.amazon.com/posts/746798
  getPublisher(method: string): KatalMetrics.Publisher {
    if (!cachedPublisher) cachedPublisher = createPublisher();
    return cachedPublisher.newChildActionPublisherForMethod(method);
  },

  createTimerStopWatch(
    name: string,
    startTime?: number
  ): KatalMetrics.Metric.TimerStopwatch {
    return new KatalMetrics.Metric.TimerStopwatch(name, startTime);
  },
};
