// The strings are mapped to the existing Identity Providers in Cognito.
export enum IdPs {
  OTP = 'OTP',
  AWSSignIn = 'AWSSignIn',
  LoginWithAmazon = 'LoginWithAmazon',
  AmazonFederate = 'AmazonFederate',
  AWSPartnerNetwork = 'AWSPartnerNetwork',
}

/**
 * This list contains prettified names of IdPs shown in the UI.
 */
export const idpToNameMap: { [key: string]: string } = {
  [IdPs.OTP]: 'One-time email passcode',
  [IdPs.AWSSignIn]: 'AWS',
  [IdPs.LoginWithAmazon]: 'Login with Amazon',
  [IdPs.AmazonFederate]: 'Amazon employee sign-in',
  [IdPs.AWSPartnerNetwork]: 'AWS Partner Network (APN)',
};

/**
 * The prio list decides IdP order in the UI.
 */
const idpUiPrio: { [key: string]: number } = {
  [IdPs.OTP]: 1,
  [IdPs.AWSSignIn]: 2,
  [IdPs.LoginWithAmazon]: 3,
  [IdPs.AmazonFederate]: 4,
  [IdPs.AWSPartnerNetwork]: 5,
};

export const getIdpUiPrio = (idp: string): number => {
  return idpUiPrio[idp] || 9000;
};

/**
 * URL params that are required by Cognito Hosted UI when signin in.
 * https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pools-app-integration.html
 */
export const requiredUrlParams = ['redirect_uri', 'response_type', 'client_id'];

/**
 * The default IDP name for Cognito Hosted UI. Specifying this, or nothing, as
 * `identity_provider` in the URL will show all login methods.
 */
export const COGNITO_IDP = 'cognito';
