import React from 'react';
import { useIntl } from 'react-intl';

import footerMessages from '../i18n/footer.messages';
import styles from './Footer.module.css';

const whatYearIsIt = new Date().getFullYear();

const Footer = () => {
  const { formatMessage } = useIntl();
  return (
    <footer className={styles.footer} role="contentinfo">
      <a
        className={styles.item}
        href="https://aws.amazon.com/privacy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {formatMessage(footerMessages.privacyLink)}
      </a>
      <span aria-hidden="true">|</span>
      <a
        className={styles.item}
        href="https://aws.amazon.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {formatMessage(footerMessages.siteTermsLink)}
      </a>
      <span aria-hidden="true">|</span>
      <span className={styles.item}>
        {formatMessage(footerMessages.copyrightInformation, {
          year: whatYearIsIt,
        })}
      </span>
    </footer>
  );
};

export default Footer;
