import React from 'react';
import { useIntl } from 'react-intl';

import metricsService from '../services/metrics';
import globalMessages from '../i18n/global.messages';

const HelpLink = () => {
  const { formatMessage } = useIntl();
  const publishClickMetric = () => {
    const metricsPublisher = metricsService.getPublisher('Help');
    metricsPublisher.publishCounterMonitor('LinkClick', 1);
  };

  return (
    <div className="awsui-util-mt-xl">
      <a
        href="/help"
        target="_blank"
        onClick={publishClickMetric}
        data-testid="help-link"
      >
        {formatMessage(globalMessages.helpLink)}
      </a>
    </div>
  );
};

export default HelpLink;
