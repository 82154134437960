import { defineMessages } from 'react-intl';

const signinMessages = defineMessages({
  header: {
    id: 'signin.header',
    defaultMessage: 'Sign in with',
    description: 'Main heading for sign in page.',
  },
  description: {
    id: 'signin.description',
    defaultMessage: 'Pick the sign-in method you prefer',
    description: 'Main description for sign in page.',
  },
});

export default signinMessages;
