import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppConfig } from './services/config';
import Header from './components/Header';
import Main from './components/Main';
import ErrorBoundary from './components/ErrorBoundary';
import SignIn from './pages/SignIn';
import EmailOTPSignIn from './pages/EmailOTPSignIn';
import AnswerChallenge from './pages/AnswerChallenge';
import Error from './pages/Error';
import Help from './pages/Help';
import './App.css';
import Footer from './components/Footer';
import useHtmlLocale from './hooks/useHtmlLocale';

function App({ config }: { config: AppConfig }) {
  useHtmlLocale();
  return (
    // TODO: we need a nice way to tell user about required route params
    <div className="App">
      <Header />
      <Main>
        <ErrorBoundary FallbackComponent={Error}>
          <BrowserRouter>
            <Switch>
              <Route path="/otp/input" render={() => <EmailOTPSignIn />} />
              <Route
                path="/otp/challenge"
                render={() => <AnswerChallenge config={config} />}
              />
              <Route path="/login" render={() => <SignIn config={config} />} />
              <Route path="/help" render={() => <Help />} />
              <Route path="/error" render={() => <Error />} />
              <Route render={() => <Error details="Invalid path" />} />
            </Switch>
          </BrowserRouter>
        </ErrorBoundary>
      </Main>
      <Footer />
    </div>
  );
}

export default App;
