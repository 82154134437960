import React, { useState, createContext, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE } from '../constants/locales';
import { LOCALE_SELECTION } from '../constants/storage';

import translations from '../i18n/translations';
import { getInitialLocaleSelection } from '../services/locale';

export const LocaleContext = createContext({
  locale: '',
  setLocale: (_: string) => {},
});

export const useLocaleContext = () => useContext(LocaleContext);

const LocaleContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState(getInitialLocaleSelection());

  const handleSetLocale = (updatedLocale: string): void => {
    localStorage.setItem(LOCALE_SELECTION, updatedLocale);
    setLocale(updatedLocale);
  };

  return (
    <LocaleContext.Provider value={{ locale, setLocale: handleSetLocale }}>
      <IntlProvider
        defaultLocale={DEFAULT_LOCALE}
        locale={locale}
        messages={translations[locale]}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
