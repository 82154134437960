import React, { useRef, useEffect, useState } from 'react';
import { FormSection } from '@amzn/awsui-components-react/polaris';
import { useIntl } from 'react-intl';
import * as KatalMetrics from '@katal/metrics';

import providersService, { IdProvider } from '../services/providers';
import { AppConfig } from '../services/config';
import metricsService from '../services/metrics';
import Container from '../components/Container';
import ProviderButton from '../components/ProviderButton';
import HelpLink from '../components/HelpLink';
import usePageTitle from '../utils/usePageTitle';
import signinMessages from '../i18n/signin.messages';

function getClientId(): string {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('client_id') || 'NA';
}

function publishSignInMetrics(
  metricsPublisher: KatalMetrics.Publisher,
  timerMetric: KatalMetrics.Metric.TimerStopwatch,
  provider: IdProvider,
  clientId: string
) {
  metricsPublisher.publish(timerMetric);
  metricsPublisher.publishCounterMonitor('SignIn', 1);
  metricsPublisher.publishCounterMonitor(`IdP:${provider.idp}`, 1);
  metricsPublisher.publishCounterMonitor(`ClientId:${clientId}`, 1);
}

function SignIn({ config }: { config: AppConfig }) {
  usePageTitle('Sign in');
  const { formatMessage } = useIntl();

  const [isNavigating, setIsNavigating] = useState(false);
  const metricsPublisher = useRef(metricsService.getPublisher('SignInPage'));
  const timerMetric = useRef(
    metricsService.createTimerStopWatch('SignInTimer').withMonitor()
  );
  const clientId = getClientId();

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor('Impressions', 1);
  }, []);

  const handleProviderClick = (provider: IdProvider): void => {
    // Only fire metrics once to avoid ex. double clicks.
    if (!isNavigating) {
      setIsNavigating(true);
      publishSignInMetrics(
        metricsPublisher.current,
        timerMetric.current,
        provider,
        clientId
      );
    }
    window.location.assign(provider.url);
  };

  const providers = providersService.getProviders(config);

  return (
    <Container>
      <FormSection
        header={
          <h1 className="form-section-h1">
            {formatMessage(signinMessages.header)}
          </h1>
        }
        description={formatMessage(signinMessages.description)}
      >
        {providers.map((provider) => (
          <ProviderButton
            provider={provider}
            key={provider.idp}
            onClick={handleProviderClick}
          />
        ))}
        <HelpLink />
      </FormSection>
    </Container>
  );
}

export default SignIn;
