import { defineMessages } from 'react-intl';

// These help messages are created in pairs, question + answer, they have
// a shared key ID which ends with either `:question` or `:answer`.
//
// Example:
//
// 'helpID:question': {
//   id: 'helpContent.helpID.question',
//   defaultMessage: 'Who is awesome?',
// },
// 'helpID:answer': {
//   id: 'helpContent.helpID.answer',
//   defaultMessage: 'You are awesome.',
// }

export const faqMessages = defineMessages({
  'doesMatterWhichSignInMethod:question': {
    id: 'helpContent.doesMatterWhichSignInMethod.question',
    defaultMessage: 'Does it matter which sign-in method I use?',
  },
  'doesMatterWhichSignInMethod:answer': {
    id: 'helpContent.doesMatterWhichSignInMethod.answer',
    defaultMessage: `No; you can use whichever sign-in method is most convenient to
      you. However, if your event required you to register you must use
      a sign-in method that uses your registration email address.`,
  },
  'canISignWithPreviousIdentity:question': {
    id: 'helpContent.canISignWithPreviousIdentity.question',
    defaultMessage:
      "I've signed into other AWS Training and Certification experiences in the past. Can I sign in with that identity now?",
  },
  'canISignWithPreviousIdentity:answer': {
    id: 'helpContent.canISignWithPreviousIdentity.answer',
    defaultMessage: `It depends. If Login with Amazon is among the sign-in methods
    you can use the same account (no need to select a region).
    However, APN Partner Central isn't supported for sign-in.`,
  },
  'whatIsLWA:question': {
    id: 'helpContent.whatIsLWA.question',
    defaultMessage: 'What is the Login with Amazon sign-in method?',
  },
  'whatIsLWA:answer': {
    id: 'helpContent.whatIsLWA.answer',
    defaultMessage: `The Login with Amazon sign-in method let's you sign in using
    using your Amazon account as a federated sign-in provider.`,
  },
  'whatIsOTP:question': {
    id: 'helpContent.whatIsOTP.question',
    defaultMessage: 'What is the "One-time email passcode" sign-in method?',
  },
  'whatIsOTP:answer': {
    id: 'helpContent.whatIsOTP.answer',
    defaultMessage: `The one-time email passcode (OTP) sign-in method sends a
    one-time passcode to your email address as authentication.`,
  },
  'whatBrowserAreSupported:question': {
    id: 'helpContent.whatBrowserAreSupported.question',
    defaultMessage: 'What internet browsers do the sign-in methods support?',
  },
  'whatBrowserAreSupported:answer': {
    id: 'helpContent.whatBrowserAreSupported.answer',
    defaultMessage: `AWS Training and Certification identity-management sign-in
    supports Chrome and Firefox's 3 latest major versions. (We make
    our best efforts to support other browsers.) Note that the
    learning experience you sign into might support a different set
    of browsers, so please refer to their support for more
    information.`,
  },
  'signWithAWSLogin:question': {
    id: 'helpContent.signWithAWSLogin.question',
    defaultMessage: 'Can I sign in with my AWS account login?',
  },
  'signWithAWSLogin:answer': {
    id: 'helpContent.signWithAWSLogin.answer',
    defaultMessage: `No, AWS account login is not supported as a sign-in method.`,
  },
  'storeSignInPassword:question': {
    id: 'helpContent.storeSignInPassword.question',
    defaultMessage: 'Will you store my sign-in password?',
  },
  'storeSignInPassword:answer': {
    id: 'helpContent.storeSignInPassword.answer',
    defaultMessage: `AWS Training and Certification identity management uses open
    auth standards to integrate with sign-in providers and doesn't
    have visibility to users' sign-in credentials.`,
  },
});

export const troubleshootingMessages = defineMessages({
  'signinMethodtoUse:question': {
    id: 'helpContent.signinMethodtoUse.question',
    defaultMessage: 'What sign-in method should I use?',
  },
  'signinMethodtoUse:answer': {
    id: 'helpContent.signinMethodtoUse.answer',
    defaultMessage: "Select the sign-in method that's most convenient for you.",
  },
  'pageSaysIdontHaveAccess:question': {
    id: 'helpContent.pageSaysIdontHaveAccess.question',
    defaultMessage: "I signed in, but the page says I don't have access.",
  },
  'pageSaysIdontHaveAccess:answer': {
    id: 'helpContent.pageSaysIdontHaveAccess.answer',
    defaultMessage: `Make sure the email address you used for sign-in matches the one
    you used to register for the event. If it doesn't, re-register
    or sign in again using matching email addresses.`,
  },
  'cantSignPreferredMethod:question': {
    id: 'helpContent.cantSignPreferredMethod.question',
    defaultMessage: "I can't sign in with my preferred method.",
  },
  'cantSignPreferredMethod:answer': {
    id: 'helpContent.cantSignPreferredMethod.answer',
    defaultMessage: `
    <ol>
      <li>Make sure the email address you used for sign-in matches the one you 
      used to register for the event. If it doesn't, re-register or sign in again 
      using matching email addresses.</li>
      <li>Select a different sign-in method.</li>
      <li>Troubleshoot your sign-in:</li>
        <ul>
          <li>Login with Amazon: <supportLink>troubleshooting help section</supportLink></li>
          <li>One-time email passcode:</li>
          <ul>
            <li>Wait 5 minutes for the passcode email to arrive before selecting Resend passcode. 
            (Internet speeds vary depending on provider and hardware.)</li>
            <li>Check your junk/spam and trash email folders.</li>
            <li>Make sure your browser is compatible.</li>
            <li>Check any VPN, firewall, or email settings to make sure they're not blocking 
            our email, email domain, and IP addresses.</li>
          </ul>
        </ul>
      <li>Restart your email app, browser, and/or computer.</li>
    </ol>
    `,
  },
  'dontHaveASignInMethod:question': {
    id: 'helpContent.dontHaveASignInMethod.question',
    defaultMessage:
      "Do I have to create an account if I don't have one for any of the sign-in methods?",
  },
  'dontHaveASignInMethod:answer': {
    id: 'helpContent.dontHaveASignInMethod.answer',
    defaultMessage: "Yes; you'll need to create an account to sign in.",
  },
  'didntReceiveOTPEmail:question': {
    id: 'helpContent.didntReceiveOTPEmail.question',
    defaultMessage: "I didn't receive the email with the one-time passcode.",
  },
  'didntReceiveOTPEmail:answer': {
    id: 'helpContent.didntReceiveOTPEmail.answer',
    defaultMessage: `If you've already waited 5 minutes for the email to arrive, please:
    <ol>
      <li>Make sure you entered the correct email address.</li>
      <li>Check your junk/spam and trash email folders.</li>
      <li>Select Resend passcode.</li>
      <li>
        Check any VPN, firewall, or email settings to make sure
        they're not blocking our email, email domain, and IP
        addresses.
      </li>
    </ol>`,
  },
  'cantFindHelpSignInIssue:question': {
    id: 'helpContent.cantFindHelpSignInIssue.question',
    defaultMessage: "I can't find help for my sign-in issue.",
  },
  'cantFindHelpSignInIssue:answer': {
    id: 'helpContent.cantFindHelpSignInIssue.answer',
    defaultMessage: `If none of the questions above fit your sign-in issue, 
    please <supportLink>reach out to customer service</supportLink>.`,
  },
});
export const privacyMessages = defineMessages({
  'complyingWithGPDR:question': {
    id: 'helpContent.complyingWithGPDR.question',
    defaultMessage:
      'How is AWS Training and Certification complying with GDPR?',
  },
  'complyingWithGPDR:answer': {
    id: 'helpContent.complyingWithGPDR.answer',
    defaultMessage: `The AWS Training and Certification team is complying with GDPR
    by providing a way for European Union members to request
    deletion of personal data and by providing users with their
    personal data with a description of how it is used. We will also
    systematically notify users if an account breach occurs.`,
  },
  'whatIsGPDR:question': {
    id: 'helpContent.whatIsGPDR.question',
    defaultMessage: 'What is the GDPR?',
  },
  'whatIsGPDR:answer': {
    id: 'helpContent.whatIsGPDR.answer',
    defaultMessage: `GDPR stands for General Data Protection Regulation, a new
    European privacy law that is enforceable after May 25, 2018. It
    protects European Union members’ fundamental right to privacy
    and the protection of personal data. It introduces robust
    requirements that will raise and harmonize standards for data
    protection, security, and compliancy.`,
  },
  'personalInfoDeletedRequest:question': {
    id: 'helpContent.personalInfoDeletedRequest.question',
    defaultMessage:
      'How can I request for my personal information to be deleted?',
  },
  'personalInfoDeletedRequest:answer': {
    id: 'helpContent.personalInfoDeletedRequest.answer',
    defaultMessage: `If you would like to request for your personal data to be
    deleted, please submit a <supportLink>request for account deletion</supportLink>. 
    Please also include the name of the learning experience you
    have in mind if possible. Prior to submitting a request, we
    recommend that you download all your data from the used learning
    experience.`,
  },
  'personalInfoDeletedDownload:question': {
    id: 'helpContent.personalInfoDeletedDownload.question',
    defaultMessage: 'How can I download a copy of my personal information?',
  },
  'personalInfoDeletedDownload:answer': {
    id: 'helpContent.personalInfoDeletedDownload.answer',
    defaultMessage: `If you would like to download a copy of your personal information 
    from a learning experience, please <supportLink>submit a request</supportLink>. 
    and include the name of the learning experience.`,
  },
});

export const tandCMessages = defineMessages({
  'whatAreTermAndConditions:question': {
    id: 'helpContent.whatAreTermAndConditions.question',
    defaultMessage:
      'What are the terms and conditions for using AWS Training and Certification identity management?',
  },
  'whatAreTermAndConditions:answer': {
    id: 'helpContent.whatAreTermAndConditions.answer',
    defaultMessage: `Please see <supportLink>AWS Training and Certification identity management terms and
    conditionst</supportLink>.`,
  },
});
