import React from 'react';
import { FormSection } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Main from '../components/Main';
import Container from '../components/Container';
import errorMessages from '../i18n/error.messages';

const AppInitFail = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="App">
      <Header />
      <Main>
        <Container>
          <FormSection
            header={
              <h1 className="form-section-h1">
                {formatMessage(errorMessages.errorHeader)}
              </h1>
            }
            description="Something went wrong."
          >
            <p>
              {formatMessage(errorMessages.initFailedInfoP1, {
                a: (chunks) => <a href={`${window.location.href}`}>{chunks}</a>,
              })}
            </p>
            <p>{formatMessage(errorMessages.initFailedInfoP2)}</p>
          </FormSection>
        </Container>
      </Main>
      <Footer />
    </div>
  );
};

export default AppInitFail;
