import { defineMessages } from 'react-intl';

const errorMessages = defineMessages({
  errorHeader: {
    id: 'error.errorHeader',
    defaultMessage: 'Oh no',
    description: 'The header title of the error page.',
  },
  errorSubHeaderDescription: {
    id: 'error.errorSubHeaderDescription',
    defaultMessage: 'Something went wrong',
    description: 'Sub header description.',
  },
  generalErrorMessage: {
    id: 'error.generalErrorMessage',
    defaultMessage: 'An error was encountered with the requested page.',
    description: 'Text for describing a general error.',
  },
  somethingWrongPleaseReload: {
    id: 'error.somethingWrongPleaseReload',
    defaultMessage:
      'Something went wrong, please reload the page and try again.',
    description: 'Text for describing a unknown error.',
  },
  unauthorizedHeader: {
    id: 'error.unauthorizedHeader',
    defaultMessage: 'Your email isn’t registered for a learning account.',
    description: 'Header for when a customer is unauthorized to sign in.',
  },
  unauthorizedSignOut: {
    id: 'error.unauthorizedSignOut',
    defaultMessage: 'Sign out and retry',
    description: 'Button for signin out when unauthorized.',
  },
  unauthorizedInformation: {
    id: 'error.unauthorizedInformation',
    defaultMessage:
      'Please contact your learning administrator to register your email. After you’ve been registered, sign out and sign in again to continue.',
    description: 'Information when a customer is unauthorized to sign in.',
  },
  unauthorizedHeaderAfterSignOut: {
    id: 'error.unauthorizedHeaderAfterSignOut',
    defaultMessage: 'You’ve been signed out',
    description: 'Header when customer has signed out.',
  },
  unauthorizedInformationAfterSignOut: {
    id: 'error.unauthorizedInformationAfterSignOut',
    defaultMessage:
      'Please try signing in again later using your registered email.',
    description: 'Information when customer has signed out.',
  },
  missingEmailHeader: {
    id: 'error.missingEmailHeader',
    defaultMessage: "Your account doesn't have an email address",
    description: 'Header for when a customer is unauthorized to sign in.',
  },
  missingEmailInformation: {
    id: 'error.missingEmailInformation',
    defaultMessage:
      "The sign-in method doesn't have an email address. Please add one or choose a different sign-in method.",
    description:
      'Information when a customer credential does not have an email.',
  },
  missingEmailInformationAfterSignOut: {
    id: 'error.missingEmailInformationAfterSignOut',
    defaultMessage: 'Please try signing in again using a method with an email.',
    description: 'Information when customer has signed out.',
  },
  initFailedInfoP1: {
    id: 'error.initFailedInfoP1',
    defaultMessage: 'Please <a>reload the page</a> and try again.',
    description: 'Information when app initiation has failed, paragraph 1.',
  },
  initFailedInfoP2: {
    id: 'error.initFailedInfoP2',
    defaultMessage:
      'We recommend using the latest version of your browser for the best experience.',
    description: 'Information when app initiation has failed, paragraph 2.',
  },
  failedSigninHeader: {
    id: 'error.failedSigninHeader',
    defaultMessage: 'Something went wrong during sign-in',
    description: 'Header for when sign in failed for an unknown reason.',
  },
  failedSigninInfo: {
    id: 'error.failedSigninInfo',
    defaultMessage:
      'There was an error during the sign-in process. Please try again later.',
    description: 'Info for when sign in failed for an unknown reason.',
  },
});

export default errorMessages;
