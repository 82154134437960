import { isDevelopment } from '../utils/env';
import { retry } from '../utils/promise';

const CONFIG_URI = '/config/config.json';

export interface AppConfig {
  clients: {
    [key: string]: string[];
  };
  hiddenClients: string[];
  cognitoDomain: string;
  metricsDomain?: string;
  metricsRealm?: string;
  region: string;
  stage: string;
  userPoolId: string;
  enableOtp: string;
  otpUserPoolId: string;
  otpUserPoolClientId: string;
  otpIdentityPoolId: string;
  otpOidcApiUrl: string;
  otpOidcApiServiceName: string;
}

let cachedConfig: AppConfig;

const init = async (): Promise<AppConfig> => {
  try {
    if (isDevelopment) {
      const response = await retry(() => import('../devconfig/config.json'));
      return (cachedConfig = response.default);
    } else {
      const response = await retry(() => fetch(CONFIG_URI), {
        retries: 3,
        interval: 100,
      });
      return (cachedConfig = await response.json());
    }
  } catch (err) {
    throw new Error(`Failed to fetch config: ${err.message}`);
  }
};

const get = (): AppConfig => {
  if (!cachedConfig) throw new Error('Need to run init.');
  return cachedConfig;
};

export default {
  init,
  get,
};
