import { defineMessages } from 'react-intl';

const globalMessages = defineMessages({
  pageTitle: {
    id: 'global.pageTitle',
    defaultMessage: 'Sign in with',
    description: 'The main page title of the page, appears in the browser tab.',
  },
  pageLogoAlt: {
    id: 'global.pageLogoAlt',
    defaultMessage: 'AWS Training and Certification',
    description: 'Image alt text for the page logo.',
  },
  helpLink: {
    id: 'global.helpLink',
    defaultMessage: 'Get help signing in',
    description: 'Link to get help for sign in.',
  },
  selectPreferredLocale: {
    id: 'global.selectPreferredLocale',
    defaultMessage: 'Select your preferred language.',
    description: 'Screen reader text for selecting another locale language.',
  },
  currentLocaleSelection: {
    id: 'global.currentLocaleSelection',
    defaultMessage: 'Current selection:',
    description: 'The currently selected locale.',
  },
});

export default globalMessages;
