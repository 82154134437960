import React, { useRef, useEffect } from 'react';
import { FormSection, ExpandableSection } from '@amzn/awsui-components-react';

import { useIntl } from 'react-intl';
import metricsService from '../services/metrics';
import { Content, HelpSection, HelpTopic } from '../constants/helpContent';
import usePageTitle from '../utils/usePageTitle';
import helpMessages from '../i18n/help.messages';

import './Help.css';

const HelpTopicExpandable = ({ topic }: { topic: HelpTopic }) => (
  <ExpandableSection header={<h3>{topic.heading}</h3>}>
    <div className="HelpTopicExpandable__content">{topic.content}</div>
  </ExpandableSection>
);

const Help = () => {
  const { formatMessage } = useIntl();
  const helpContent = Content();
  usePageTitle(formatMessage(helpMessages.pageTitle));
  const metricsPublisher = useRef(metricsService.getPublisher('Help'));

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor('Impressions', 1);
  }, []);

  return (
    <div className="Help awsui-grid">
      <div className="awsui-row">
        <div className="col-xxs-10 offset-xxs-1 col-s-8 offset-s-2 col-xl-6 offset-xl-3">
          <FormSection
            header={
              <h1 className="form-section-h1">
                {formatMessage(helpMessages.headerTitle)}
              </h1>
            }
          >
            {helpContent.sections.map((section: HelpSection, i: number) => (
              <div className="awsui-util-mt-l" key={i}>
                <h2>{section.heading}</h2>
                <ul className="HelpTopicExpandable__list awsui-list-unstyled">
                  {section.topics.map((topic: HelpTopic, j: number) => (
                    <li key={j} className="HelpTopicExpandable__item">
                      <HelpTopicExpandable topic={topic} />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </FormSection>
        </div>
      </div>
    </div>
  );
};

export default Help;
