import React from 'react';
import { Button } from '@amzn/awsui-components-react/polaris';
import { useIntl } from 'react-intl';

import { IdProvider } from '../services/providers';
import { IdPs } from '../constants/providers';
import providerMessages from '../i18n/provider.messages';

import './ProviderButton.css';

const ProviderButton = ({
  provider,
  onClick,
}: {
  provider: IdProvider;
  onClick: Function;
}) => {
  const { formatMessage } = useIntl();
  const handleClick = (event: Event): void => {
    event.preventDefault();
    onClick(provider);
  };

  return (
    <div className="ProviderButton">
      <Button
        onClick={handleClick}
        variant="normal"
        data-testid="signIn-providerButton"
      >
        {IdPs[provider.idp as IdPs]
          ? formatMessage(providerMessages[provider.idp as IdPs])
          : provider.idp}
      </Button>
    </div>
  );
};

export default ProviderButton;
