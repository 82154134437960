import { defineMessages } from 'react-intl';

const helpMessages = defineMessages({
  pageTitle: {
    id: 'help.pageTitle',
    defaultMessage: 'Sign in help',
    description: 'The page title of the help page, appears in the browser tab.',
  },
  headerTitle: {
    id: 'help.headerTitle',
    defaultMessage: 'What can we help you with?',
    description: 'Main header of the help page.',
  },
  sectionTroubleshooting: {
    id: 'help.sectionTroubleshooting',
    defaultMessage: 'Troubleshooting',
    description: 'Troubleshooting section header of the help page.',
  },
  sectionFAQ: {
    id: 'help.sectionFAQ',
    defaultMessage: 'FAQ',
    description: 'FAQ section header of the help page.',
  },
  sectionPrivacy: {
    id: 'help.sectionPrivacy',
    defaultMessage: 'Privacy',
    description: 'Privacy section header of the help page.',
  },
  sectionTermAndConditions: {
    id: 'help.sectionTermAndConditions',
    defaultMessage: 'Terms and conditions',
    description: 'Term and conditions section header of the help page.',
  },
});

export default helpMessages;
