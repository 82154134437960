import { defineMessages } from 'react-intl';

import { IdPs } from '../constants/providers';

/**
 * The provider key correlates to a IDP.
 */
const providerMessages = defineMessages({
  [IdPs.OTP]: {
    id: 'provider.OTP',
    defaultMessage: 'One-time email passcode',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AWSSignIn]: {
    id: 'provider.AWSSignIn',
    defaultMessage: 'AWS',
    description: 'Button label for the identity provider.',
  },
  [IdPs.LoginWithAmazon]: {
    id: 'provider.LoginWithAmazon',
    defaultMessage: 'Login with Amazon',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AmazonFederate]: {
    id: 'provider.AmazonFederate',
    defaultMessage: 'Amazon employee sign-in',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AWSPartnerNetwork]: {
    id: 'provider.AWSPartnerNetwork',
    defaultMessage: 'AWS Partner Network (APN)',
    description: 'Button label for the identity provider.',
  },
});

export default providerMessages;
