import { AppConfig } from './config';
import {
  idpToNameMap,
  getIdpUiPrio,
  requiredUrlParams,
  COGNITO_IDP,
} from '../constants/providers';

export interface IdProvider {
  name: string;
  idp: string;
  url: string;
}

const getIdpName = (idp: string): string => idpToNameMap[idp] || idp;

const getIdpUrl = (idp: string, domain: string): string => {
  const baseUrl = `https://${domain}/oauth2/authorize`;
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set('identity_provider', idp);
  return `${baseUrl}?${urlParams.toString()}`;
};

const providerPrioCompare = (idp1: string, idp2: string) => {
  return getIdpUiPrio(idp1) - getIdpUiPrio(idp2);
};

type ValidatedParams = { isValid: boolean; errorMsg: string };
const validateRequiredParams = (
  urlParams: URLSearchParams
): ValidatedParams => {
  const missingParams = requiredUrlParams.filter(
    (param) => !Boolean(urlParams.get(param))
  );

  const rtnValidParams: ValidatedParams = { isValid: true, errorMsg: '' };
  if (missingParams.length) {
    rtnValidParams.isValid = false;
    rtnValidParams.errorMsg = `Missing url paramters: [${missingParams.join(
      ', '
    )}].`;
  }
  return rtnValidParams;
};

const getProviders = (config: AppConfig): IdProvider[] => {
  const urlParams = new URLSearchParams(window.location.search);
  const validatedURLParams: ValidatedParams = validateRequiredParams(urlParams);
  if (!validatedURLParams.isValid) {
    throw new Error(validatedURLParams.errorMsg);
  }
  const clientId = urlParams.get('client_id') as string;
  const providerList = config.clients[clientId];

  if (!providerList) {
    throw new Error('Unsupported client_id');
  }

  providerList.sort(providerPrioCompare);

  return (config.clients[clientId] || [])
    .filter(
      (idp) => !config.hiddenClients || !config.hiddenClients.includes(idp)
    )
    .map((idp) => ({
      idp,
      name: getIdpName(idp),
      url: getIdpUrl(idp, config.cognitoDomain),
    }));
};

/**
 * Checks if the customer has specified a preferred IDP other than COGNITO.
 * Will also verify that the suggested IDP is in the list of available IDPs
 * for the given client ID.
 */
const getPreferredIdp = (config: AppConfig): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  const provider = urlParams.get('identity_provider');

  if (!provider || provider.toLowerCase() === COGNITO_IDP) return;

  const clientId = urlParams.get('client_id') as string;
  const providerList = config.clients[clientId];

  if (providerList && providerList.indexOf(provider) === -1) return;

  return provider;
};

export default {
  getIdpUrl,
  getProviders,
  getPreferredIdp,
};
