import React from 'react';

import { useIntl } from 'react-intl';

import logo from '../assets/logo-color-dark.svg';
import globalMessages from '../i18n/global.messages';
import LocaleMenu from './LocaleMenu';

import './Header.css';

const Header = () => {
  const { formatMessage } = useIntl();
  return (
    <header className="Header">
      <img
        className="img"
        src={logo}
        alt={formatMessage(globalMessages.pageLogoAlt)}
      />
      <LocaleMenu />
    </header>
  );
};

export default Header;
